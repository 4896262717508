<template>
  <div class="app">
    <div class="gg-container">
      <div class="product-batch-id">
        <div class="product-card">
          <h2 class="border-bottom">Product Description</h2>
          <div class="tracking-order">
            <div class="product-cards">
              <h3>
                Batch ID: <span>{{ this.batch_details.id }}</span>
              </h3>
              <h3>
                Product ID: <span>{{ this.batch_details.product.id }}</span>
              </h3>
              <h3>
                Batch Status : <span>{{ this.batch_details.status }}</span>
              </h3>
              <div class="items">
                <h3>Product Name ({{ this.batch_details.product.name }})</h3>
                <div class="description">
                  <h3>Product Description</h3>
                  <p v-if="this.product_details.description">
                    {{ this.product_details.description }}
                  </p>
                  <p v-else>N/A</p>
                </div>
                <div else></div>
              </div>
            </div>
            <div class="flex">
              <img
                :src="mediaRootUrl + this.batch_details.qrcode"
                alt="QR Code of the product"
              />
              <img
                :src="this.product_details.image"
                alt="Image of the product"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="product-batch-id">
        <div class="product-card">
          <h1 class="border-bottom">Source Information</h1>
          <div class="source-information">
            <div class="source-info">
              <h3>Source Name : {{ batch_details.source.full_name }}</h3>
              <h3>Food Mile : {{ this.foodMile }} KM</h3>
              <div class="image" v-if="user_details.image == !''">
                <img :src="user_details.image" alt="Image of the source" />
              </div>
              <h3>Source Description:</h3>
              <p v-if="batch_details.source.user_info.user_description">
                {{ batch_details.source.user_info.user_description }}
              </p>
              <p v-else>N/A</p>
            </div>
            <div class="maps" id="map">
              <l-map style="height: 400px" :zoom="zoom" :center="markerLatLngSource">
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                ></l-tile-layer>
                <l-marker :lat-lng="markerLatLngSource"></l-marker>
              </l-map>
            </div>
          </div>
        </div>
      </div>
      <div class="product-card">
        <div class="product-batch">
          <h2 class="border-bottom">Product Journey Map</h2>
        </div>
        <div class="product-journey">
          <!-- Deman Card -->
          <div class="steps">
            <div class="step completed">
              <h2 class="step-title border-bottom">Demand</h2>
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <span class="material-icons"> location_on </span>
                </div>
              </div>
            </div>
            <!--Processing and Packaging -->
            <div class="step completed">
              <h2 class="step-title border-bottom">Processing & Packaging</h2>
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <span class="material-icons"> shopping_bag </span>
                </div>
              </div>
            </div>
            <!-- Aggregated Card -->
            <div
              class="step completed"
              v-if="cooperativeDetail && cooperativeDetail.length !== 0"
            >
              <h2 class="step-title border-bottom">Aggregated</h2>
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <span class="material-icons"> local_shipping </span>
                </div>
              </div>
            </div>
            <!-- Received Card -->
            <div
              class="step completed"
              v-if="this.batch_details.status == 'Received'"
            >
              <h2 class="step-title border-bottom">Recieved</h2>
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <span class="material-icons"> local_shipping </span>
                </div>
              </div>
            </div>
            <!-- Outlet Card -->
            <div class="step completed" v-if="batch_details.outlet">
              <h2 class="step-title border-bottom">Outlet</h2>
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <span class="material-icons"> local_shipping </span>
                </div>
              </div>
            </div>
            <!-- Delivered Card -->
            <div class="step completed" v-if="user_profile.active == true">
              <h2 class="step-title border-bottom">Delivered</h2>
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <span class="material-icons"> local_shipping </span>
                </div>
              </div>
            </div>
          </div>
          <div class="product">
            <!-- Demand Card -->
            <div class="product-card product-line">
              <div class="product-description">
                <h1 class="border-bottom">Demand</h1>
                <h2>
                  <span class="material-icons"> today </span>Date :
                  {{ this.batch_details.created_at }}
                </h2>
                <h2>
                  <span class="material-icons"> face </span>By: Green Growth
                </h2>
                <h2>
                  <span class="material-icons"> location_on </span>
                  Gairi Gaun ,Kathmandu
                </h2>
              </div>
            </div>
            <!-- Dispatched Card -->
            <div class="product-card product-line">
              <div class="product-description">
                <h1 class="border-bottom">Dispatch</h1>
                <h2>
                  <span class="material-icons"> today </span>Date :{{
                    this.batch_details.dispatched_at
                  }}
                </h2>
                <h2>
                  <span class="material-icons"> face </span>By:
                  {{ batch_details.source.full_name }}
                </h2>
                <h2 v-if="source && source.length > 0">
                  <span class="material-icons" v> location_on </span>
                  {{ source.user_info.street_address }}
                  {{ source.user_info.city }}
                  {{ source.user_info.state }}
                </h2>
                <h2 v-else>
                  <span class="material-icons" v> location_on </span>
                  N/A
                </h2>
              </div>
            </div>
            <!-- Aggregated Card -->
            <div
              class="product-card product-line"
              v-if="cooperativeDetail && cooperativeDetail.length !== 0"
            >
              <div class="product-description">
                <h1 class="border-bottom">Aggregated</h1>
                <h2>
                  <span class="material-icons"> today </span>Date :{{
                    this.batch_details.dispatched_at
                  }}
                </h2>
                <h2>
                  <span class="material-icons"> face </span>By:
                  {{ cooperativeDetail.name }}
                </h2>
                <h2>
                  <span class="material-icons"> location_on </span>
                  {{ cooperativeDetail.addressline }}
                </h2>
              </div>
            </div>
            <!-- Received Card -->
            <div class="product-card product-line">
              <div class="product-description">
                <h1 class="border-bottom">Recieved</h1>
                <h2>
                  <span class="material-icons"> today </span>Date :{{
                    this.batch_details.received_at
                  }}
                </h2>
                <h2>
                  <span class="material-icons"> face </span>By: Green Growth
                </h2>
                <h2>
                  <span class="material-icons"> location_on </span> Gairi Gaun
                  ,Kathmandu
                </h2>
              </div>
            </div>
            <!-- Outlet Card -->
            <div class="product-card product-line" v-if="batch_details.outlet">
              <div class="product-description">
                <h1 class="border-bottom">Outlet</h1>
                <h2>
                  <span class="material-icons"> today </span>Date :{{
                    this.batch_details.received_at
                  }}
                </h2>
                <h2><span class="material-icons"> face </span>By:{{batch_details.outlet.full_name}}</h2>
                <h2>
                  <span class="material-icons"> location_on </span> {{batch_details.outlet.user_info.street_address}},{{batch_details.outlet.user_info.city}},{{batch_details.outlet.user_info.state}}
                </h2>
              </div>
            </div>
            <!-- Delivered Card -->
            <div
              class="product-card product-line"
              v-if="user_profile.active == true"
            >
              <div class="product-description">
                <h1 class="border-bottom">Delivered</h1>
                <h2>
                  <span class="material-icons"> today </span>Date :{{
                    this.todays_date
                  }}
                </h2>
                <h2>
                  <span class="material-icons"> face </span>By: Green Growth
                </h2>
                <h2>
                  <span class="material-icons"> location_on </span>
                  {{ user_profile.user_info.street_address }}
                  {{ user_profile.user_info.state }}
                  {{ user_profile.user_info.city }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid" v-if="batch_details.partner !== null">
        <div class="product-footer">
          <h1>Powered By</h1>
          <img src="../assets/images/001.png" alt="" />
        </div>
        <div class="logo-section">
          <div
            class="logo-wrap"
            v-for="(partner, index) in batch_details.partner"
            :key="index"
          >
            <img :src="mediaRootUrl + partner.logo" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Baato from "@klltech/baato-js-client";
// import mapboxgl from "mapbox-gl";

import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  name: "TabContent",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  computed: {
    ...mapState([
      "batch_list",
      "product_list",
      "userList",
      "user_profile",
      "error_message",
      "response_message",
    ]),
    source() {
      return this.batch_details.source;
    },
    cooperativeDetail() {
      let cooperative_details = [];
      this.batch_details.source.cooperative.forEach((element) => {
        cooperative_details = element;
      });
      return cooperative_details;
    },
    markerLatLngs() {
      console.log(this.batch_list);
      // let start_latitude = this.batch_details.source.user_info.latitude;
      // let start_longitude = this.batch_details.source.user_info.longitude;
      // console.log(start_latitude, start_longitude);
      // this.startLatLongString =
      //   this.start_latitude + "," + start_longitude;
      // this.endLatLongString = end_latitude + "," + end_longitude;
      // this.markerLatLng = [start_latitude, start_longitude];
    },
  },

  created() {
    this.listBatch();
    this.listProducts();
    this.listUsers();
    this.listProfile();
  },

  mounted: function() {
    // console.log(this.batch_list);
  },
  updated() {
    // console.log(this.batch_list);
    this.trackFoodMile();
  },

  data() {
    return {
      batch_id: this.$route.params.id,
      foodMile: "",
      single_product: [],
      start_latitude: "",
      start_longitude: "",
      end_latitude: "27.69196999798448",
      end_longitude: "85.35205697301235",
      startLatLongString: [],
      endLatLongString: [],
      product_id: "",
      source_id: "",
      batch_details: [],
      cooperative_data: [],
      user_details: [],
      product_details: "",
      errors: [],
      success: [],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      // center: [47.313220, -1.319482],
      // markerLatLng: [47.313220, -1.319482],
      center: [27.695612, 85.3230575],
      markerLatLngSource: [],
      mediaRootUrl: process.env.VUE_APP_USER_PANEL,
      // mediaRootUrl: process.env.VUE_APP_BASE_URL,
      todays_date: new Date().toISOString().split("T")[0],
      foodMile: "",
    };
  },
  watch: {
    batch_list: function() {
      // console.log(this.batch_list);
      this.batch_details = [];

      this.batch_list.forEach((element) => {
        if (element.id == this.batch_id) {
          this.batch_details = element;
        }
      });
      this.markerLatLngSource= [];
      this.start_latitude = this.batch_details.source.user_info.latitude;
      this.start_longitude = this.batch_details.source.user_info.longitude;
      this.markerLatLngSource = [this.start_latitude,this.start_longitude];
      // console.log("Startddd", this.start_latitude, this.start_longitude);
      // console.log("Marker", this.markerLatLngSource);
    },
    product_list: function() {
      this.product_id = this.batch_details.product.id;
      this.product_list.forEach((element) => {
        if (element.id == this.product_id) {
          // console.log(element);
          this.product_details = element;
          // console.log(product_details);
        }
      });
    },

    user_list: function() {
      this.user_details = [];
      this.source_id = this.batch_details.source.id;
      this.userList.forEach((element) => {
        if (element.id == this.source_id) {
          // console.log(element.id);
          this.user_details = element;
        }
      });
    },

    "$route.params.id"() {
      window.scrollTo(0, 0);
      // window.reload(100);
      this.batch_details = this.batch_list.find(
        (evt) => evt.id == this.$route.params.id
      );
      this.product_id = this.batch_details.product.id;
      // console.log(this.product_id);
      this.source_id = this.batch_details.source.id;
    },
  },
  methods: {
    ...mapActions(["listBatch", "listProfile", "listUsers", "listProducts"]),
    trackFoodMile() {
      let startLatLongString = this.start_latitude + "," + this.start_longitude;
      let endLatLongString = this.end_latitude + "," + this.end_longitude;
      console.log("Start", startLatLongString);
      console.log("End", endLatLongString);
      new Baato.Routing({
        key: "bpk.jzDjWvq0gy4AgvGjP8Wv7bE6_nCoYJfuecX35KmpV_jW",
      })
        .addPoints([startLatLongString, endLatLongString])
        .setVehicle("car")
        .getBest()
        .setBaseUrl(`https://api.baato.io/api`)
        .doRequest()
        .then((response) => {
          let foodMile = [];
          response.forEach((element) => {
            foodMile = element.distanceInMeters;
          });
          this.foodMile = foodMile / 1000;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.product-footer {
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    height: 80px;
  }
}

.logo-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .logo-wrap {
    text-align: center;
    img {
      height: 80px;
      max-width: 100%;
      margin: 15px 5px;
    }
  }
}
.source-info {
  display: grid;
  grid-gap: 15px;
  padding: 30px;
  justify-content: space-between;
  // align-items: center;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.maps {
  height: 400px;
  width: 100%;
  iframe {
    height: 100%;
    width: 100%;
  }
}
.source-information {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column-reverse;
}

.items {
  p {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
  h3 {
    font-size: 25px;
    line-height: 1.5;
  }
}
.description {
  // border: 1px solid #4ba069;
  padding: 5px 5px;
}
.form-sucess {
  color: #1fda60;
}
.notification_seen h4 {
  font-weight: normal;
}
.notification_seen span {
  font-size: 16px;
}

.md-dialog-container {
  max-width: 482px;
  background: #fff;
  overflow-y: auto;
}

.md-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4ba069;
  color: #fff;
  h4 {
    margin: 0px;
    width: 100%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
  }
  span {
    cursor: pointer;
    &:hover {
      // background: #d2d2d2;
      // border-radius: 50%;
      color: #000;
    }
  }
}
.track-order {
  margin-top: 10px;
  height: 45px;
  width: 180px;
  background-color: #fff;
  border: 1px solid #4dd4f9;
  border-radius: 5px;
  color: #4dd4f9;
  font-size: 18px;
  padding: 0px 30px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  &:hover {
    background: #4dd4f9;
    color: #ffffff;
  }
}
.modal-content {
  display: grid;
  justify-content: center;
  padding: 10px 28px;
  place-items: center;
  input {
    height: 51px;
    width: 433px;
    border: 2px solid #707070;
    padding: 16px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;
  }
}
</style>
