<template>
  <section class="home-banner">
    <div v-if="banner_list.active = true">
      <agile
        class="main"
        ref="main"
        :options="options"
      >
        <div class="slide" v-for="(banner, index) in banner_list" :key="index">
          <div class="image">
            <a :href="banner.link" target="_blank">
              <img :src="banner.image" style="width: 100vw; max-width: 100vw" />
            </a>
          </div>
        </div>
        <template slot="prevButton"
          ><i class="fas fa-chevron-left"></i
        ></template>
        <template slot="nextButton"
          ><i class="fas fa-chevron-right"></i
        ></template>
      </agile>
    </div>
    <div class="product-categories">
      <div class="description-card">
        <h1>Product Categories</h1>
        <ul>
          <li
            v-for="(category_obj, index) in categories_list"
            :key="index"
            v-if="checkValidCategory(category_obj)"
          >
            <router-link :to="'/all-products/' + category_obj.name"
              ><span>{{ category_obj.name }}</span></router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div></div>
    <!-- <div class="switch-button">
      <div class="icon"><i class="fas fa-exchange-alt"></i></div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdcog158T3AMiegySPUBefYaAh38-IGD4wAuPzf1BNkFZNqEw/viewform?fbclid=IwAR2wCTQpf2870e7eE4sHyd8GI3DRE7st5A2V9fOZ3A6cf9lfXJdbQUXNahI"
          target="_blank"
        >
        <span>Switch to classic mode</span>
        </a>
    </div> -->
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { VueAgile } from "vue-agile";
export default {
  comments: {
    agile: VueAgile,
  },
  data() {
    return {
      options: {
        autoplay: true,
        autoplaySpeed: 7000,
        infinite: true,
        dots: false,
        navButtons: true,
        pauseOnHover: true,
      },
    };
  },
  computed: {
    ...mapState(["categories_list", "banner_list"]),
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  created() {
    this.listCategories();
    this.listBanner();
  },
  methods: {
    ...mapActions(["listCategories", "listBanner"]),
    checkValidCategory(categoryObj) {
      let haveActiveProduct = false;

      let allCategoryList = categoryObj.product_category;
      allCategoryList.forEach((item) => {
        if (!haveActiveProduct) {
          let checkOutOfStock = false;
          let checkNoInventry = false;
          checkOutOfStock = item.product_status == "Available" ? true : false;
          checkNoInventry = parseInt(item.total_quantity) > 1 ? true : false;

          if (checkOutOfStock && checkNoInventry) {
            haveActiveProduct = true;
          }
        }
      });

      return haveActiveProduct;
    },

    // checkActiveBanner(bannerObj) {
    //   let haveActiveBanner = false;

    //   let allBannerList = bannerObj.banner_obj;
    //   allBannerList.forEach((banner) => {
    //     if (!haveActiveBanner) {
    //       let checkActiveBanner = false;
    //       checkActiveBanner = banner.status == "active" ? true : false;
    //       if (checkActiveBanner) {
    //         haveActiveBanner = true;
    //       }
    //     }
    //   });
    //   return haveActiveBanner;
    // },
  },
};
</script>

<style lang="scss">
// .main {
//   margin-bottom: 30px;
// }
.banner-des {
  position: absolute;
  background: rgba($color: #000000, $alpha: 0.3);
  padding: 15px;
  width: 100%;
  bottom: 0;
  h1 {
    font-size: 24px;
    margin-bottom: 5px;
  }
  a {
    span {
      font-size: 20px;
      color: #ffcb5f;
      font-weight: 700;
    }
  }
  .md-theme-default a:not(.md-button) {
    color: #ffcb5f !important;
  }
}
.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}
.agile__actions {
  justify-content: flex-end;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
  margin: 10px 5px;
}
.thumbnails .agile__nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.thumbnails .agile__nav-button--prev {
  left: -45px;
}
.thumbnails .agile__nav-button--next {
  right: -45px;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: rgb(165, 158, 158);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: rgb(31, 133, 56);
}
.agile__nav-button {
  background: rgb(31, 133, 56);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  &:hover {
    i {
      color: #ffcb5f;
    }
  }
}
.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  // display: flex;
  height: 700px;
  justify-content: center;
  .image {
    img {
      width: 100vw;
      object-fit: cover;
    }
  }
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}
</style>
