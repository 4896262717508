import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import Routes from "./routes";
import axios from "axios";
import VueMaterial from "vue-material";
import "leaflet/dist/leaflet.css";
// import "@/assets/scss/style.scss";

import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

import { createStore } from "./store";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import VueAgile from "vue-agile";

Vue.use(VueAgile);

Vue.use(VueMaterial);

Vue.config.productionTip = false;
Vue.use(VueRouter);

axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL;

const router = new VueRouter({
  routes: Routes,
  mode: "history",
});
const store = createStore();

const eventBus = new Vue();
export { eventBus as default };

new Vue({
  store,
  router: router,
  // PulseLoader,
  render: (h) => h(App),
}).$mount("#app");
