<template>
  <header>
    <div class="top-nav gg-container">
      <div class="logo">
        <a href="/">
          <img
            src="../assets/images/greengrowth-logo.svg"
            alt="logo of green growth"
          />
        </a>
      </div>
      <div class="header-links">
        <div class="links" :class="{ 'toggle-menu': showMobileMenu }">
          <router-link to="/">
            <span @click="showMobileMenu = !showMobileMenu">Home</span>
          </router-link>
          <router-link :to="{ name: 'allproducts', params: { id: 'All' } }">
            <span @click="showMobileMenu = !showMobileMenu">All Products</span>
          </router-link>
          <router-link
            :to="{ name: 'staticPage', params: { slug: 'about-us' } }"
          >
            <span @click="showMobileMenu = !showMobileMenu">About Us</span>
          </router-link>
          <router-link
            :to="{ name: 'staticPage', params: { slug: 'contact-us' } }"
          >
            <span @click="showMobileMenu = !showMobileMenu">Contact</span>
          </router-link>
          <!-- <router-link
            :to="{ name: 'trackOrderPage', params: { slug: this.single_batch_list.id } }"
          >
            <span>Track Order</span>
          </router-link> -->
        </div>
        <div class="searchbox" :class="{ 'show-search-login': showMobileMenu }">
          <form method="GET" action="/search-now" novalidate="true">
            <input
              type="search"
              name="search"
              placeholder="Search......"
              v-model="search"
              class="searchbox-input"
              required
            />
            <button type="submit" class="searchbox-submit">
              <span class="searchbox-icon">
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </button>
          </form>
        </div>
        <div class="user-profile">
          <div
            class="button join-login"
            v-if="!token"
            :class="{ 'show-search-login': showMobileMenu }"
          >
            <button
              class="button button-outline"
              type="submit"
              id="join"
              @click="showRegistrationForm = true"
            >
              <span>Join</span>
            </button>
            <button
              class="button"
              type="submit"
              id="log-in"
              @click="showLoginModal = true"
            >
              <span>Log In</span>
            </button>
          </div>
          <div v-if="token" class="notification">
            <button
              class="notification-link"
              @click="showDropDown = !showDropDown"
            >
              <div class="header-notification">
                <i class="far fa-bell"></i>
                <div
                  class="badge"
                  v-if="countNotification && countNotification > 0"
                >
                  <span>{{ countNotification }}</span>
                </div>
              </div>
            </button>
            <div
              v-on-clickaway="away"
              @click="click"
              v-if="
                notification_obj &&
                filterNotification &&
                filterNotification.length > 0 &&
                showDropDown
              "
              class="notification-dropdown"
            >
              <span class="dropdown-tooltip">
                <i class="fas fa-sort-up"></i>
              </span>
              <ul>
                <div class="header-notification" v-on-clickaway="away">
                  <h2 class="dropdown-title">Notifications</h2>
                  <button
                    class="button-mark button-outline"
                    @click="markAllSeenNotification()"
                  >
                    Mark all Read
                  </button>
                </div>
                <li
                  v-for="(notification, index) in filterNotification.slice(
                    0,
                    4
                  )"
                  :key="index"
                >
                  <router-link
                    to=""
                    class="notification_seen"
                    v-if="notification.seen"
                  >
                    <h4>
                      {{ capitalizeFirstLetter(notification.title) }}
                      <span style="font-size: 16px"
                        >on Date: {{ notification.created_at }}</span
                      >
                    </h4>
                    <span>{{
                      capitalizeFirstLetter(notification.description)
                    }}</span>
                  </router-link>
                  <router-link to="" class="notification_unseen" v-else>
                    <h4 @click="seenThisMessage(notification.id)">
                      {{ capitalizeFirstLetter(notification.title) }}
                      <label style="font-size: 15px"
                        >on Date: {{ notification.created_at }}</label
                      >
                    </h4>
                    <span @click="seenThisMessage(notification.id)">{{
                      capitalizeFirstLetter(notification.description)
                    }}</span>
                  </router-link>
                </li>
                <li v-on-clickaway="away">
                  <router-link :to="{ name: 'notificationPage' }" class="seen">
                    <strong>View All Notifications &raquo;</strong>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <user-avatar />
          <router-link :to="{ name: 'mycartproducts' }" class="basket-link">
            <div class="shopping-basket">
              <!-- <span class="material-icons shopping-bag"> shopping_basket </span> -->
              <img
                src="../assets/images/basket_icon.svg"
                alt="shopping basket icon"
              />
              <div class="badge">
                <span>{{
                  cartCount > 0
                    ? cartCount
                    : product_in_cart && product_in_cart.length > 0
                    ? product_in_cart.length
                    : 0
                }}</span>
                <!-- <span v-if="cardproduct_obj.length>0">{{cardproduct_obj.length}}</span>
                <span v-else>0</span> -->
              </div>
            </div>
          </router-link>
        </div>
        <button class="btn" @click="showMobileMenu = !showMobileMenu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
    <!-- <form method="GET" action="/search-now" novalidate="true">
      <div class="bottom-nav">
        <div class="gg-container bottom-nav-inner">
          <div class="searchbox">
            <input type="search" name="search" placeholder="Search......" v-model="search"
              class="searchbox-input" required />
              <button class="searchbox-submit">
                <span class="searchbox-icon">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </button>
          </div>
        </div>
      </div>
    </form> -->

    <LoginForm
      v-if="showLoginModal"
      @open="(showRegistrationForm = true)((showLoginModal = false))"
      @close="showLoginModal = false"
    />
    <RegistrationForm
      v-if="showRegistrationForm"
      @open="(showRegistrationForm = false)((showLoginModal = true))"
      @close="showRegistrationForm = false"
    />
    <NewForm
      v-if="showNewForm && !token"
      @close="showNewForm = false"
      @open="(showRegistrationForm = true)((showNewForm = false))"
    />
  </header>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import LoginForm from "./LoginForm.vue";
import RegistrationForm from "./RegistrationForm.vue";
import NewForm from "./ConfirmRegistration.vue";
import UserAvatar from "./UserAvatar.vue";
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  template: '<p v-on-clickaway="away">Click away</p>',
  computed: {
    ...mapState([
      "token",
      "user_profile",
      "notification_obj",
      "cartCount",
      "success_message",
      "error_message",
      "response_message",
      "batch_list",
    ]),
    filterNotification() {
      let allNotifications = [];

      if (this.user_profile) {
        allNotifications = this.notification_obj;
        allNotifications = allNotifications.filter((row) => {
          return row.notification_to.id == this.user_profile.id;
        });
      }

      return allNotifications.reverse();
    },
    countNotification: function () {
      let allNotifications = [];

      if (this.user_profile) {
        allNotifications = this.notification_obj;
        allNotifications = allNotifications.filter((row) => {
          return !row.seen && row.notification_to.id == this.user_profile.id;
        });
      }

      let total = 0;
      if (allNotifications && allNotifications.length > 0) {
        total = allNotifications.length;
      }
      return total;
    },
  },

  // props:["showRegistrationForm"],
  data() {
    return {
      showLoginModal: false,
      showRegistrationForm: false,
      showNewForm: true,
      showMobileMenu: true,
      search: "",
      showDropDown: false,
      currentRouteName: this.$route.name,
      product_in_cart: JSON.parse(localStorage.getItem("localCartData")),
      single_batch_list: "",
    };
  },
  components: {
    LoginForm,
    RegistrationForm,
    NewForm,
    UserAvatar,
  },
  mounted() {
    this.single_batch_list = [];
    this.batch_list.forEach((element) => {
      this.single_batch_list = element;
    });
  },
  created() {
    if (this.$store.state.token != "" || this.$store.state.token) {
      this.listProfile();
      this.listUnSeenNotifications();
    }
    // Create Empty Local Cart
    if (!localStorage.getItem("localCartData")) {
      let emptyArray = [];
      localStorage.setItem("localCartData", JSON.stringify(emptyArray));
    }
    this.listBatch();
  },
  methods: {
    ...mapActions(["listProfile", "listUnSeenNotifications", "listBatch"]),
    searchProduct() {
      this.$store.dispatch("searchProducts", this.search);
      this.search = "";
    },

    // Hide dropdown on clicking outside it
    away: function () {
      console.log("clicked away");
    },
    click: function () {
      this.showDropDown = true;
    },
    away: function () {
      this.showDropDown = false;
    },
    seenThisMessage(notificationId) {
      this.$store.dispatch("seenThisNotification", notificationId).then(() => {
        if (this.success_message == "success") {
          location.reload();
        }
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    markAllSeenNotification() {
      this.$store.dispatch("seenAllNotificationOfUser").then(() => {
        if (this.success_message == "success") {
          location.reload();
        }
      });
    },
    showLogin(showLoginModal) {
      this.showLoginModal = showLoginModal;
    },
  },
  watch: {
    "$route.name"() {
      if (this.currentRouteName == "activeateUserRegister") {
        showRegistrationForm = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification_seen h4 {
  font-weight: normal;
}
.notification_seen span {
  font-size: 16px;
}

.md-dialog-container {
  max-width: 482px;
  background: #fff;
  overflow-y: auto;
}
.md-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4ba069;
  color: #fff;
  h4 {
    margin: 0px;
    width: 100%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
  }
  span {
    cursor: pointer;
    &:hover {
      // background: #d2d2d2;
      // border-radius: 50%;
      color: #000;
    }
  }
}
.track-order {
  margin-top: 10px;
  height: 45px;
  width: 180px;
  background-color: #fff;
  border: 1px solid #4dd4f9;
  border-radius: 5px;
  color: #4dd4f9;
  font-size: 18px;
  padding: 0px 30px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  &:hover {
    background: #4dd4f9;
    color: #ffffff;
  }
}
.modal-content {
  display: grid;
  justify-content: center;
  padding: 10px 28px;
  place-items: center;
  input {
    height: 51px;
    width: 433px;
    border: 2px solid #707070;
    padding: 16px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
  }
}
</style>
